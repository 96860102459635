<template>
  <div>Home</div>
</template>

<script>
export default {
  name: "Home",
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  mounted() {
    if (this.user.role === "admin") {
      this.$router.push({ name: "accounting" });
    } else if (this.user.role === "reviewer") {
      this.$router.push({ name: "reviews" });
    }
  },
};
</script>

<style scoped></style>
